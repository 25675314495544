import React from 'react';
import ZFSS_Error from './error';
import { Link } from 'react-router-dom';

class ZFSS_ErrorHandler {
	constructor() {

	}

	parseError(err) {
		let error = new ZFSS_Error(err);

		this.overrideErrorMessages(error);

		return error;
	}

	overrideErrorMessages(error) {
		if(!error) return;

		switch(error.getCode()) {
			case 'WRONG-EMAIL':
			case 'USER-NOT-EXISTS':
			case 'WRONG-EMAIL-PASSWORD':
			case 'PERMISSION-FAILURE':
				error.setMessage('Podany adres e-mail nie istnieje lub podane hasło jest nieprawidłowe');
				break;
			case 'PROFILE-NOT-FOUND':
				error.setMessage(<>Nie posiadasz aktywnego profilu u wybranego kontrahenta. Przejdź do <Link to="/profile">ustawień profilu</Link> i stwórz profil lub aktywuj roboczy</>);
				break;
			case 'PROFILE-EXISTS':
				error.setMessage(<>Profil klienta dla tego kontrahenta już istnieje. Przejdź do listy profili w celu edycji lub zaakceptowania profilu</>);
				break;
			case 'ERR-UNDEFINED':
				error.setMessage('Wystąpił nieoczekiwany błąd. Prosimy spróbować ponownie lub skontaktować się z administratorem serwisu');
				break;
			// case 'REGULATION-NOT-MEET':
			// case 'REGULATION-NOT-EXISTS':
			// 	error.setMessage('Brak możliwości przeliczenia wniosku, skontaktuj się z administratorem serwisu');
			// 	break;
			case 'SHOW-DATA-ERROR':
				if(error.getMessage() === 'Brak danych dla podanego ID') {
					error._code = 'RECORD-NOT-FOUND';
				}
				break;
		}
	}
}

export default ZFSS_ErrorHandler;
