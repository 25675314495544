import React from 'react';
import styled from 'styled-components';

import ProfileImage from '../../components/ProfileImage';
import { useAuth } from '../../contexts/User';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { faBell } from '@fortawesome/free-regular-svg-icons'

const Wrapper = styled.div`
	display: flex;
	height: 100%;
	align-items: center;
	line-height: normal;
`;

const UserPhoto = styled(ProfileImage)`
	vertical-align: middle;
	display: inline-block;
	line-height: 0;
	position: relative;
	margin: 2px;
	margin-right: 10px;
	border-radius: 50%;

	img {
		border-radius: inherit;
		width: 100%;
		height: 100%;
		vertical-align: top;
		object-fit: cover;
	}
`;

const UserInfo = styled.div`
	display: inline-block;
	margin-right: 13px;
`;

const UserName = styled.h4`
	margin: 0;
	padding: 0;
	line-height: 1;
	color: #575757;
`;

const UserTitle = styled.span`
	display: block;
	font-size: 12px;
	color: #b5b5c3;
`;

const AngleDown = styled(FontAwesomeIcon)`
	color: #457b9d;
`;

const ProfilePane = (props) => {
	const auth = useAuth();

	return (
		<Wrapper href="#" onClick={e => e.preventDefault()} className={props?.className ?? null}>
			<UserPhoto name={`${auth.user?.first_name} ${auth.user?.last_name}`} size="34" />

			<UserInfo>
				<UserName>
					{`${auth.user?.first_name} ${auth.user?.last_name}`}
				</UserName>
				<UserTitle>
					{auth.user?.permission_name}
				</UserTitle>
			</UserInfo>

			<AngleDown icon={faAngleDown} />
		</Wrapper>
	);
};

export default ProfilePane;
