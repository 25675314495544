import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.a`

`;

const Indicator = styled.span`

`;

const NotificationBell = () => {
	return (
		<Wrapper href="#" onClick={e => e.preventDefault()}>
			<Indicator />
		</Wrapper>
	);
};

export default NotificationBell;
